import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuthStore } from './hooks/useAuthStore';
import { AuthStatus } from "./types/slices/authType";
import { Backdrop, CircularProgress } from '@mui/material';
import { RoutesMap } from "./types/common/routes";
import { HomeView } from './views/Home/HomeView';
import { RequestDepartmentsView } from './views/RequestDepartments/RequestDepartments';
import { LoginView } from "./views/Login/LoginView";
import { RestrictedRoute } from "./routes";
import { Protected } from "./components/Layout/Protected";
import { CustomSnackbar } from "./components/common/CustomSnackbar/CustomSnackbar";
import { useSelector } from "./redux/store";
import { CustomBackdrop } from "./components/common/CustomBackdrop/CustomBackdrop";
import { DepartamentView } from "./views/Departament/Departament";
import { DepartamentForm } from "./views/Departament/DepartamentForm";
import { OwnerView } from "./views/Owner/Owner";
import { OwnerForm } from "./views/Owner/OwnerForm";
import { PaymentConceptView } from "./views/PaymentConcept/PaymentConcept";
import { PaymentConceptForm } from "./views/PaymentConcept/PaymentConceptForm";
import { SeasonView } from "./views/Season/Season";
import { SeasonForm } from "./views/Season/SeasonForm";
import { SpecialDateForm } from "./views/SpecialDate/SpecialDateForm";
import { SpecialDateView } from "./views/SpecialDate/SpecialDate";
import { ReservationView } from "./views/Reservation/ReservationView";
import { RenditionView } from "./views/Rendition/RenditionView";
import { ReservationConfirm } from "./views/Reservation/ReservationConfirm";
import { RenditionHistory } from "./views/Rendition/RenditionHistory";
import { MyProfileView } from "./views/MyProfile/MyProfile";
import { AffiliatedFamilyView } from "./views/AffiliatedFamily/AffiliatedFamily";
import { AffiliatedFamilyForm } from "./views/AffiliatedFamily/AffiliatedFamilyForm";
import { RenditionOpen } from "./views/RenditionAdmin/RenditionOpen";
import { ReservationAdminView } from "./views/ReservationAdmin/ReservationAdmin";
import { ReservationAdminConfirm } from "./views/ReservationAdmin/ReservationAdminConfirm";
import { ReservationAdminList } from "./views/ReservationAdmin/ReservationAdminList";
import { CompanyForm } from "./views/Company/CompanyForm";
import { CompanyView } from "./views/Company/Company";
import { UserView } from "./views/Users/User";
import { UserForm } from "./views/Users/UserForm";
import { RenditionAdminList } from "./views/RenditionAdmin/RenditionAdminList";
import { RenditionAdminPaidList } from "./views/RenditionAdmin/RenditionAdminPaidList";
import { CessionList } from "./views/Cession/CessionList";
import { CessionForm } from "./views/Cession/CessionForm";
import { CessionHistory } from "./views/Cession/CessionHistory";
import { ReportAdminRendition } from "./views/ReportAdmin/ReportAdminRendition";
import { ReportAdminDepartment } from "./views/ReportAdmin/ReportAdminDepartment";
import { ReportAdminAvailibility } from "./views/ReportAdmin/ReportAdminAvailibility";
import { SettingAdminCancellation } from "./views/SettingAdmin/SettingAdminCancellation";
import { SettingAdminCession } from "./views/SettingAdmin/SettingAdminCession";
import { SettingAdminSeason } from "./views/SettingAdmin/SettingAdminSeason";
import { SettingAdminChecking } from "./views/SettingAdmin/SettingAdminChecking";
import { SettingAdminCessation } from "./views/SettingAdmin/SettingAdminCessation";
import { SettingAdminSpecialDate } from "./views/SettingAdmin/SettingAdminSpecialDate";
import { SettingSuperAdminReservation } from "./views/SettingSuperAdmin/SettingSuperAdminReservation";
import { PaymentConceptDetail } from "./views/PaymentConcept/PaymentConceptDetail";
import { CessionFormEdit } from "./views/Cession/CessionFormEdit";
import { ServiceView } from "./views/Servicess/Service";
import { ServiceForm } from "./views/Servicess/ServiceForm";
import { ConceptCategoryView } from "./views/ConceptCategory/ConceptCategory";
import { ConceptCategoryForm } from "./views/ConceptCategory/ConceptCategoryForm";
import { ReservationAdminEdit } from "./views/ReservationAdmin/ReservationAdminEdit";
import { GuestAdminView } from "./views/GuestAdmin/GuestAdmin";
import { GuestAdminForm } from "./views/GuestAdmin/GuestAdminForm";
import { Toaster } from 'react-hot-toast';
import './App.css'; //no comentar
import { ReservationAdminInvited } from "./views/ReservationAdmin/ReservationAdminInvited";
import { OwnerByDepartament } from "./views/Departament/OwnerByDepartament";
import { SettingAdminCycleRendition } from "./views/SettingAdmin/SettingAdminCycleRendition";
import { BuildingView } from "./views/Building/Building";
import { BuildingForm } from "./views/Building/BuildingForm";
import { DepartamentByBuilding } from "./views/Building/DepartamentByBuilding";
import { ReportAdminRenditionPayment } from "./views/ReportAdmin/ReportAdminRenditionPayment";
import { NotificationView } from "./views/Notification/Notification";
import { AdministratorList } from "./views/Administrator/AdministratorList";
import { AdministratorForm } from "./views/Administrator/AdministratorForm";
import { PaymentConceptBuildingView } from "./views/PaymentConceptBuilding/PaymentConceptBuilding";
import { PaymentConceptBuildingForm } from "./views/PaymentConceptBuilding/PaymentConceptBuildingForm";
import { RenditionBuildingOpen } from "./views/RenditionBuilding/RenditionBuildingOpen";
import { RenditionBuildingList } from "./views/RenditionBuilding/RenditionBuildingList";
import { RenditionBuildingPaidList } from "./views/RenditionBuilding/RenditionBuildingPaidList";
import { ReservationBlockedView } from "./views/ReservationBlocked/ReservationBlocked";
import { ReservationBlockedConfirm } from "./views/ReservationBlocked/ReservationBlockedConfirm";
import { ReservationBlockedList } from "./views/ReservationBlocked/ReservationBlockedList";
import { PaymentConceptDetail2 } from "./views/PaymentConcept/PaymentConceptBuildingDetail";

function App() {
  /* hooks */
  const { verifySession, status, user } = useAuthStore();
  const location = useLocation();

  const { alertType, message, show } = useSelector(state => state.snackbarSlice)
  // const { showBackdrop } = useSelector(state => state.backdropSlice)

  useEffect(() => {
    verifySession();
  }, []);

  if (status === AuthStatus.VERIFYING) {
    return <Backdrop open={true} >
      <CircularProgress color="inherit" />
    </Backdrop>
  }

  return (
    <>
    <Routes>
      {/* RUTAS PUBLICAS */}
      {/* <Route path="/" element={<Navigate to={RoutesMap.MAIN} replace />} /> */}
      <Route path="/*" element={<Navigate to={RoutesMap.LOGIN} replace />} />

      {/* RUTAS PRIVADAS */}
      {
        status === AuthStatus.NOT_AUTHENTICATED ? (
          <>
          <Route path='/login' element={<LoginView />} />
          <Route path="/*" element={<Navigate to={RoutesMap.LOGIN} replace />} />
          </>

        ) : (

          <>
          <Route path="/" element={<Protected />}>
            {/* vita principal por default */}
            {/* <Route element={<RestrictedRoute pathRequired={RoutesMap.MAIN} />}>
              <Route path={RoutesMap.MAIN} element={<HomeView />} /> 
            </Route> */}
            
            {/* ------------------------- VIEWS OWNER AND FAMILY ------------------------------- */}
            {/* PROFILE */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.MY_PROFILE} />}>
              <Route path={RoutesMap.MY_PROFILE} element={<MyProfileView />} />
            </Route>

            {/* HOME */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.HOME} />}>
              <Route path={RoutesMap.HOME} element={<HomeView />} />
            </Route>

            {/* REQUEST DEPARTMENTS */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.REQUEST_DEPARTMENTS} />}>
              <Route path={RoutesMap.REQUEST_DEPARTMENTS} element={<RequestDepartmentsView />} />
            </Route>

            {/* RESERVATION */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION} />}>
              <Route path={RoutesMap.RESERVATION} element={<ReservationView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_CONFIRM} />}>
              <Route path={RoutesMap.RESERVATION_CONFIRM} element={<ReservationConfirm />} />
            </Route>

            {/* RENDICIONES OWNER*/}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION} />}>
              <Route path={RoutesMap.RENDITION} element={<RenditionView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_HISTORY} />}>
              <Route path={RoutesMap.RENDITION_HISTORY} element={<RenditionHistory />} />
            </Route>

            {/* AFFILIATED FAMILY */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.AFFILIATED_FAMILY} />}>
              <Route path={RoutesMap.AFFILIATED_FAMILY} element={<AffiliatedFamilyView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.AFFILIATED_FAMILY_FORM} />}>
              <Route path={RoutesMap.AFFILIATED_FAMILY_FORM} element={<AffiliatedFamilyForm />} />
            </Route>

            {/* ------------------------- VIEWS ADMIN ------------------------------- */}

            {/* ADMINISTRATOR */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.ADMINISTRATOR} />}>
              <Route path={RoutesMap.ADMINISTRATOR} element={<AdministratorList />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.ADMINISTRATOR_FORM} />}>
              <Route path={RoutesMap.ADMINISTRATOR_FORM} element={<AdministratorForm />} />
            </Route>

              {/* BUILDING */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.BUILDING} />}>
                <Route path={RoutesMap.BUILDING} element={<BuildingView />} />
              </Route>
              <Route element={<RestrictedRoute pathRequired={RoutesMap.BUILDING_FORM} />}>
                <Route path={RoutesMap.BUILDING_FORM} element={<BuildingForm />} />
              </Route>
              <Route element={<RestrictedRoute pathRequired={RoutesMap.DEPARTAMENT_BY_BUILDING} />}>
                <Route path={RoutesMap.DEPARTAMENT_BY_BUILDING} element={<DepartamentByBuilding />} />
            </Route>

            {/* DEPARTAMENT */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.DEPARTAMENT} />}>
              <Route path={RoutesMap.DEPARTAMENT} element={<DepartamentView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.DEPARTAMENT_FORM} />}>
              <Route path={RoutesMap.DEPARTAMENT_FORM} element={<DepartamentForm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.OWNER_BY_DEPARTAMENT} />}>
              <Route path={RoutesMap.OWNER_BY_DEPARTAMENT} element={<OwnerByDepartament />} />
            </Route>

            {/* OWNER */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.OWNER} />}>
              <Route path={RoutesMap.OWNER} element={<OwnerView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.OWNER_FORM} />}>
              <Route path={RoutesMap.OWNER_FORM} element={<OwnerForm />} />
            </Route>

            {/* GUEST */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.GUEST_ADMIN} />}>
              <Route path={RoutesMap.GUEST_ADMIN} element={<GuestAdminView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.GUEST_ADMIN_FORM} />}>
              <Route path={RoutesMap.GUEST_ADMIN_FORM} element={<GuestAdminForm />} />
            </Route>

            {/* PAYMENT CONCEPT */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT} element={<PaymentConceptView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_FORM} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_FORM} element={<PaymentConceptForm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_DETAIL} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_DETAIL} element={<PaymentConceptDetail />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_DETAIL2} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_DETAIL2} element={<PaymentConceptDetail2 />} />
            </Route>

            {/* PAYMENT CONCEPT BUILDING */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_BUILDING} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_BUILDING} element={<PaymentConceptBuildingView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_BUILDING_FORM} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_BUILDING_FORM} element={<PaymentConceptBuildingForm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.PAYMENT_CONCEPT_BUILDING_DETAIL} />}>
              <Route path={RoutesMap.PAYMENT_CONCEPT_BUILDING_DETAIL} element={<PaymentConceptDetail />} />
            </Route>

            {/* SEASON */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SEASON} />}>
              <Route path={RoutesMap.SEASON} element={<SeasonView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SEASON_FORM} />}>
              <Route path={RoutesMap.SEASON_FORM} element={<SeasonForm />} />
            </Route>

            {/* SPECIAL DATES */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SPECIAL_DATES} />}>
              <Route path={RoutesMap.SPECIAL_DATES} element={<SpecialDateView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SPECIAL_DATES_FORM} />}>
              <Route path={RoutesMap.SPECIAL_DATES_FORM} element={<SpecialDateForm />} />
            </Route>

            {/* SERVICES */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SERVICES} />}>
              <Route path={RoutesMap.SERVICES} element={<ServiceView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SERVICES_FORM} />}>
              <Route path={RoutesMap.SERVICES_FORM} element={<ServiceForm />} />
            </Route>

            {/* CONCEPT CATEGORY */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONCEPT_CATEGORY} />}>
              <Route path={RoutesMap.CONCEPT_CATEGORY} element={<ConceptCategoryView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CONCEPT_CATEGORY_FORM} />}>
              <Route path={RoutesMap.CONCEPT_CATEGORY_FORM} element={<ConceptCategoryForm />} />
            </Route>

            {/* RESERVATION ADMIN */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN} element={<ReservationAdminView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN_CONFIRM} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN_CONFIRM} element={<ReservationAdminConfirm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN_LIST} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN_LIST} element={<ReservationAdminList />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN_EDIT} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN_EDIT} element={<ReservationAdminEdit />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN_INVITED} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN_INVITED} element={<ReservationAdminInvited />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_ADMIN_BLOCKED} />}>
              <Route path={RoutesMap.RESERVATION_ADMIN_BLOCKED} element={<ReservationBlockedView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RESERVATION_BLOCKED_CONFIRM} />}>
              <Route path={RoutesMap.RESERVATION_BLOCKED_CONFIRM} element={<ReservationBlockedConfirm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.BLOCKED_LIST} />}>
              <Route path={RoutesMap.BLOCKED_LIST} element={<ReservationBlockedList />} />
            </Route>

            {/* RENDITION ADMIN */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_OPEN} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_OPEN} element={<RenditionOpen />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_LIST} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_LIST} element={<RenditionAdminList />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_PAID} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_PAID} element={<RenditionAdminPaidList />} />
            </Route>

            {/* RENDITION BUILDING ADMIN */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_BUILDING_OPEN} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_BUILDING_OPEN} element={<RenditionBuildingOpen />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_BUILDING_LIST} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_BUILDING_LIST} element={<RenditionBuildingList />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.RENDITION_ADMIN_BUILDING_PAID} />}>
              <Route path={RoutesMap.RENDITION_ADMIN_BUILDING_PAID} element={<RenditionBuildingPaidList />} />
            </Route>

            {/* CESSION */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CESSION_LIST} />}>
              <Route path={RoutesMap.CESSION_LIST} element={<CessionList />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CESSION_FORM} />}>
              <Route path={RoutesMap.CESSION_FORM} element={<CessionForm />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CESSION_FORM_EDIT} />}>
              <Route path={RoutesMap.CESSION_FORM_EDIT} element={<CessionFormEdit />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.CESSION_HISTORY} />}>
              <Route path={RoutesMap.CESSION_HISTORY} element={<CessionHistory />} />
            </Route>

            {/* REPORT */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_ADMIN_RENDITION_PAY} />}>
              <Route path={RoutesMap.REPORT_ADMIN_RENDITION_PAY} element={<ReportAdminRenditionPayment />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_ADMIN_RENDITION} />}>
              <Route path={RoutesMap.REPORT_ADMIN_RENDITION} element={<ReportAdminRendition />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_ADMIN_DEPARTMENT} />}>
              <Route path={RoutesMap.REPORT_ADMIN_DEPARTMENT} element={<ReportAdminDepartment />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.REPORT_ADMIN_AVAILABILITY} />}>
              <Route path={RoutesMap.REPORT_ADMIN_AVAILABILITY} element={<ReportAdminAvailibility />} />
            </Route>
            {/* SETTING */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_CANCELLATION} />}>
              <Route path={RoutesMap.SETTING_ADMIN_CANCELLATION} element={<SettingAdminCancellation />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_CESSION} />}>
              <Route path={RoutesMap.SETTING_ADMIN_CESSION} element={<SettingAdminCession />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_SEASON} />}>
              <Route path={RoutesMap.SETTING_ADMIN_SEASON} element={<SettingAdminSeason />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_CHECKING} />}>
              <Route path={RoutesMap.SETTING_ADMIN_CHECKING} element={<SettingAdminChecking />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_CESSATION} />}>
              <Route path={RoutesMap.SETTING_ADMIN_CESSATION} element={<SettingAdminCessation />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_CYLE_RENDITION} />}>
              <Route path={RoutesMap.SETTING_ADMIN_CYLE_RENDITION} element={<SettingAdminCycleRendition />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_ADMIN_SPECIAL_DATE} />}>
              <Route path={RoutesMap.SETTING_ADMIN_SPECIAL_DATE} element={<SettingAdminSpecialDate />} />
            </Route>
            
            {/* ------------------------- VIEWS SUPERADMIN ------------------------------- */}
            {/* COMPANIES */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.COMPANIES} />}>
              <Route path={RoutesMap.COMPANIES} element={<CompanyView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.COMPANIES_FORM} />}>
              <Route path={RoutesMap.COMPANIES_FORM} element={<CompanyForm />} />
            </Route>
            {/* USERS */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.USERS} />}>
              <Route path={RoutesMap.USERS} element={<UserView />} />
            </Route>
            <Route element={<RestrictedRoute pathRequired={RoutesMap.USERS_FORM} />}>
              <Route path={RoutesMap.USERS_FORM} element={<UserForm />} />
            </Route> 
            {/* SETTING */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.SETTING_SUPERADMIN_RESERVATION} />}>
              <Route path={RoutesMap.SETTING_SUPERADMIN_RESERVATION} element={<SettingSuperAdminReservation />} />
            </Route> 
            {/* ------------------------- VIEWS FAMILIES ------------------------------- */}
            {/* <Route path="/*" element={<Navigate to={RoutesMap.MAIN} state={{ from: location }} replace />} /> */}

            {/* ------------------------- VIEWS NOTIFICATION ------------------------------- */}

              {/* Notificacion */}
            <Route element={<RestrictedRoute pathRequired={RoutesMap.NOTIFICATION} />}>
                <Route path={RoutesMap.NOTIFICATION} element={<NotificationView />} />
              </Route>
          </Route>
          </>
        )
      }
    </Routes>
      <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
               style:
               {
                  background: '#c9c7c7',
                  color: '#fff'
               }
            }}
      />
    </>
  );
}

export default App;
